import { useContext } from "react";
import { StepperContext } from "../../contexts/StepperContext";
import Modal from "./Modal";
import allGenderSports from "../../json/allSportsGender.json"
function Final(props) {
  const { userData } = useContext(StepperContext);

  return (
    <div className="flex flex-col w-full">
      <div className="mb-10 mt-5">
        <h5 className="text-black font-bold text-xl mb-2 md:text-2xl ">
          Confirm Submission
        </h5>
        <p className="text-sm tracking-normal font-medium text-gray-600">
          Please Confirm Your Details...
        </p>
        <h2 style={{fontWeight: 'bold', color: 'blue'}}>Note : Type is only required for Athletics, Weight-Lifting and Power-Lifting.</h2>
      </div>
      
      <h1> Selected Sport:  <e style={{fontWeight: 'bold'}}>{userData.sport}</e></h1>
      <br/>
      <table style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>
        <tr style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>
          <th>Name</th>
          <th>Contact</th>
          <th>Email</th>
        </tr>
        <tr style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>
          <td style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>{userData["firstNameCaptain"] || ""}  {userData["lastNameCaptain"] || ""} (C)</td>
          <td style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>{userData["contactCaptain"] || ""}</td>
          <td style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>{userData["emailCaptain"] || ""}</td>
        </tr>
        <tr style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>
          <td style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>{userData["firstNameViceCaptain"] || ""} {userData["lastNameViceCaptain"] || ""} (VC)</td>
          <td style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>{userData["contactViceCaptain"] || ""}</td>
          <td style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>{userData["emailViceCaptain"] || ""}</td>
        </tr>
        
        </table>
        <br/>
        <h1 style={{fontWeight: 'bold'}}>Team Details :</h1>
        <br/>
      <table style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>
    <th>Player</th>
    {(userData.sport == "Taekwondo(Mix)")&&(<th>Gender</th>)}
          <th>Contact</th>

          {allGenderSports[userData.sport].subEvents ? <th>Subevent</th> : null}
      
      {userData["team"].map((player, index) => (
        <tr key={index} style={{textAlign: 'center', border: "1px solid black",
        borderCollapse: "collapse"}}>
          
              
            <td style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>
              {player.player }
              </td>
            {(userData.sport == "Taekwondo(Mix)")&&(<td style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>
              {player.gender }
              </td>)}
              <td style={{textAlign: 'center', border: "1px solid black",
  borderCollapse: "collapse"}}>
              {player.number }
              
              </td>
              {allGenderSports[userData.sport].subEvents ? <td style={{textAlign: 'center', border: "1px solid black",
                borderCollapse: "collapse"}}>{player.subEvent}</td> : null}
              
              
            
        </tr>

        
      ))}
      </table>
      <Modal gameData={userData} onClose={props.onClose} modalShow={props.modalShow}/>
      {/* <pre>{JSON.stringify(userData, null, 4)}</pre> */}
      
    </div >
  );
}
export default Final;
