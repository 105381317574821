import { useAuthState } from "react-firebase-hooks/auth";
import { NavLink, useNavigate } from "react-router-dom";
import { auth, cbs } from "../../pages/Firebase";
import '../../css/modal.css';

function Modal(props) {
    // console.log(props)
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();


    // console.log(props.gameData)
    function sendToFirebase(){
        console.log(props.gameData)
        cbs(props.gameData,user.uid);
        // window.location.reload(true);
    }

    return (
        <div className={`${props.modalShow ? 'flex':'hidden'} fixed inset-0 z-[21]  min-h-screen items-center p-4`} onClick={props.onClose} id="confirmation">
            <div className="relative w-full max-w-2xl overflow-y-auto rounded-xl bg-white p-12 shadow-lg" onClick={e => e.stopPropagation()}>
                <h2 className="text-3xl font-bold">Confirm ?</h2>
                
                <div className="mt-8 flex space-x-2">
                    <button type="submit" className="bg-blue-600 text-white uppercase py-2 px-4 rounded-md font-semibold cursor-pointer border-2 border-blue-600  transition duration-200 ease-in-out shadow-md shadow-blue-200 disabled:shadow-sm disabled:bg-blue-300 disabled:border-blue-300 disabled:cursor-not-allowed" onClick={sendToFirebase}  >
                        Confirm
                    </button> 
                    <button type="button" className="rounded-md border border-gray-200 bg-white px-5 py-2.5" onClick={props.onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Modal;
