import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Book from './book'
import '../css/rules.css'
import items from "../json/sportsRules.json"
import allGames from "../json/allSports.json"
import pdfImg from "./pdf.png"
function Rules() {
  const [modalShow, setModalShow] = useState(false);
  const[sportRules, setSportRules] = useState("Football");

  return (
    <>
      <div className='bg-black text-white overflow-hidden min-h-screen  '>
        <h1 className='text-center text-[2.5em] m-[20px] font-semibold'>Sports Events 
        <br/>
         </h1>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-4/5 m-auto'>
        {Object.keys(allGames).map((key) => {
          return (<div className='m-[3vw] relative hover:scale-110 cursor-pointer transition ease duration-800' onClick={() => {setModalShow(true); setSportRules(key)}}>
            <img className='m-auto rounded-md h-[200px] ' src={allGames[key].toString()} alt='' />
            <div className='absolute top-0 left-0 bg-black/[0.6] h-full w-full opacity-0 hover:opacity-100 transition ease duration-800 rounded-md'>
              <h1 className='flex justify-center items-center h-full text-4xl font-semibold text-center'>{key}</h1>
            </div>
          </div>)
        
          })
        }
        
        </div>
      </div>

      {/* Football */}
      <Book trigger={modalShow} setTrigger={setModalShow} >
        <a href={items[sportRules]["pdf"].toString()} target='_blank'><img className='absolute h-[50px] top-[20px] right-[20px]' src='//live.staticflickr.com/65535/52351477307_fdc74d9325_w.jpg' alt='Download PDF'/></a>
        <h1  className='text-4xl font-semibold pb-[20px] text-center'>Rules: {sportRules}</h1>
        <p className='p-[10px]'>
          <ul className='list-disc leading-7'>
          {items[sportRules]["rules"].map((item) => <li>{item} </li>)}
          {console.log(items[sportRules]["organisers"])}
          </ul>
        </p>
      </Book>

      

     
    </>
  )
}

export default Rules
