import React from 'react';
import './Page1.css';
// import back from './websitenpm.svg';

const Page1 = ({prevStep, nextStep, handleChange, values}) => {

    const next= e => {
      if(values["institute"].length===0 || values["contingent"].length===0 ){
        alert("Please fill all the required fields");
      }
      else{
        e.preventDefault();
        
        nextStep();
      }
    }


  return (
    <div>
       
            <div className="page1-container">
              {/* <img src={back} alt="back" className='reg-back-img' /> */}
              <h2 className="page1-heading">
                Fill General Details
              </h2>
              <div className="insti">
                <label for="institute" className="insti-name-heading">
                  Institute Name <span style={{color: 'red'}}>*</span>
                </label>
                <input
                  type="text"
                  id="institute"
                  name="institute"
                  onChange={handleChange('institute')}
                  defaultValue={values.institute}
                  className="insti-input"
                />
              </div>
              <div className="participate-as">
                <label for="gender" className="participate-as-label">
                  Participate As: <span style={{color: 'red'}}>*</span>
                </label>
                <select
                  name="contingent"
                  id="contingent"
                  onChange={handleChange('contingent')}
                  defaultValue={values.contingent}
                  className="participate-as-select"
                >
                  <option value=""> Select</option>
                  <option value="As a Contingent"> As a Contingent </option>
                  <option value="As a Team"> As a Team</option>
                </select>
              </div>
            
              <div className="page1-next">
              
                <button onClick={next} className="page1-next-button">
                  Next
                </button>
              </div>
            </div>
    </div>
  )
}

export default Page1
