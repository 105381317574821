import { data } from "autoprefixer";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Router, Routes, useNavigate } from "react-router-dom";
import Profile from "../components/Profile";
import { auth, getFromSession, getProfile, getSports, storeToSession } from "./Firebase";
import Form from "./form";
import RegistrationForm from "./registrationForm";
import Thankyou from "../components/steps/thankYou";
import Loading from "./loding";
import Registerdsport from "./Registerdsport";

import Rules from "./rules";


const Dashboard = () => {
  const [user, loading, error] = useAuthState(auth);
  const [isFetched, setIsFetched] = useState(false);
  const [registeredSports, setRegisteredSports] = useState([]);
  const [userData, setUserData] = useState({});
  const [sportdetailselected,setSportdetailselected] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      async function callAsync() {
        const docRef = await getProfile(user.uid);
        if (docRef.exists()) {
          const sportsRefs = await getSports(user.uid);
          console.log(sportsRefs, "sportsRefs");
          const arr = [];
          for (const [key, value] of Object.entries(sportsRefs)) {
            console.log(key, value);
            arr.push({ id: key, ...value});
          }
          setRegisteredSports(arr);
          setUserData(docRef.data());
          storeToSession('user_data', docRef.data());
          storeToSession('user', user);
          storeToSession('sports', arr);
          setIsFetched(true);
        } else {
          navigate("/reg");
        }
      }
      callAsync();
    }
    else{
      navigate("/");
    }
  }, [user, loading]);

  if (loading || isFetched == false) {
    return (
      <Loading />
    )
  }
  else if (user) {
    return (
      <>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
          <div className="text-white bg-gray-800" >
            <Profile registeredSports={registeredSports} image={user.photoURL} userData={userData} setSportdetailselected={setSportdetailselected} sportdetailselected={sportdetailselected} />
          </div>
          <div className="col-span-3">
            <Routes>
              <Route path="" element={<Form registeredSports={registeredSports} />} />
              <Route path="registerdsports" element={<Registerdsport registeredSports={registeredSports} sportdetailselected={sportdetailselected}/>} />
              <Route path="rules" element={<Rules />} />
              <Route path="form" element={<Form registeredSports={registeredSports} />} />
              <Route path="thankyou" element={<Thankyou />} />
            </Routes>
          </div>

        </div>
      </>
    );
  }

}
export default Dashboard;
