const StepperControl = ({ handleClick, currentStep, steps,openModal , radio, setRadio}) => {
  return (
    <div className="container flex justify-end mt-4 mb-8 p-3 md:p-10">
      {currentStep > 1 && (
        <button
          onClick={() => handleClick()}
          className={`bg-white text-gray-600 uppercase py-2 px-4 rounded-md shadow-md mr-2 font-semibold cursor-pointer border-2 border-gray-300   transition duration-200 ease-in-out ${
            currentStep === 1 ? "opacity-50 cursor-not-allowed " : ""
          }`}
        >
          Back
        </button>
      )}
      {radio && (
      <button
         onClick={() => {currentStep === steps.length ? openModal():handleClick("next")}}  
        className="bg-blue-600 text-white uppercase py-2 px-4 rounded-md font-semibold cursor-pointer border-2 border-blue-600  transition duration-200 ease-in-out shadow-md shadow-blue-200 disabled:shadow-sm disabled:bg-blue-300 disabled:border-blue-300 disabled:cursor-not-allowed"
        // disabled={true}
     >
        {currentStep === steps.length? "Submit" : "Next"}
      </button>
      )}
    </div>
  );
};
export default StepperControl;
