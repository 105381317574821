import items from '../json/allSportsGender.json'
function Validation(newStep, userData, subEvents) {
  if (newStep === 1) {
    if (userData.length === 0 || !("sport" in userData)) {
      alert("Please Choose A sport");
      return false;
    }
  }
  if (newStep === 2) {
    if (userData.length === 0 || !("firstNameCaptain" in userData) || userData['firstNameCaptain'].length === 0 || !("lastNameCaptain" in userData) || userData['lastNameCaptain'].length === 0 || !("emailCaptain" in userData) || userData['emailCaptain'].length === 0 || !("contactCaptain" in userData) || userData['contactCaptain'].length === 0 || !("firstNameViceCaptain" in userData) || userData['firstNameViceCaptain'].length === 0 || !("lastNameViceCaptain" in userData) || userData['lastNameViceCaptain'].length === 0 || !("emailViceCaptain" in userData) || userData['emailViceCaptain'].length === 0 || !("contactViceCaptain" in userData) || userData['contactViceCaptain'].length === 0) {
      alert("Please fill all the details correctly!");
      return false;
    }
  }
  if (newStep === 3) {
    console.log(userData.sport)
    console.log(subEvents)
    if (!("team" in userData) || userData['team'].length < items[userData.sport]["min"]) {
      alert("Numbers Of Players Should be between " + items[userData.sport]["min"] + " and " + items[userData.sport]["max"])
      return false;
    }
    // console.log(userData['team']);
    // console.log(userData['team'].length);
    for (var i = 0; i < userData['team'].length; i += 1) {
      var name = userData['team'][i]['player'];
      var player = userData['team'][i];
      console.log(name)
      console.log(player)
      if ((name === "")){
        alert("Fill details of each player carefully")
        return false;
      }
      if ((subEvents)&&(player.subEvent === "")) {
        alert("Fill details of each player carefully")
        return false;
      }
      if (userData.sport == "Taekwondo(Mix)") {
        if (player.gender === "") {
          alert("Fill details of each player carefully")
          return false;
        }
      }
    }

  }

  return true;

}
export default Validation;