function TextField(props) {
  return (
    <>
    <div className="mb-4">   
        <div className="relative">
        <input
          type={props.type || 'text'}
          name={props.name}
          pattern={props.pattern}
          id={props.id}
          className={
           `px-4 pb-2.5 pt-4 text-sm shadow-sm peer  focus:outline-none focus:ring-0  peer border-2 rounded-lg  appearance-none outline-none w-full  focus:border-blue-500 focus:text-blue-500 placeholder-transparent focus:placeholder-blue-600 ${props.customClass}`
          }
          placeholder={props.placeholder}
          autoComplete={props.autoComplete}
          disabled={props.disabled}
          max={props.max}
          maxLength={props.maxLength}
          min={props.min}
          minLength={props.minLength||0}
          readOnly={props.readOnly||false}
          required={props.required||false}
          step={props.step}
          value={props.value}
          onChange={props.onChange}
        />
          <label htmlFor={props.id} className="absolute text-sm font-normal text-gray-600 peer-focus:text-blue-600 peer-required:after:content-['*'] peer-required:after:ml-0.5 peer-required:after:text-red-500  duration-300 transform -translate-y-4 scale-75 left-2 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 "> {props.label}</label>
        </div>

        {/* <p id="outlined_error_help" className="mt-2 text-xs text-blue-600 "><span className="font-medium">Oh, snapp!</span> {props.customClass}</p>     */}
    </div>
    </>
  );
}
export default TextField;
