import React, { useEffect } from 'react';
import './Stepper.css';

const Stepper = ({step_}) => {

    const handleChangeCss=(step_)=>{
        for (let i = 1; i <=step_; i++) {
            document.getElementById(i).style.background = "#86efac";
          }
          for (let i = step_+1; i <=3; i++) {
            document.getElementById(i).style.background = "";
          }
        
    }
    useEffect(() => {
        handleChangeCss(step_);
    
      
    }, [step_])
    
  return (
    <div>
        <section className="steps-container">
            
            <div className=" rounded-lg my-1 bg-gray-100 text-blue-900 text-xl">
              <ul className=" py-1 space-x-0 flex justify-between ">
                <li id ="1" className="w-1/3 py-4 px-2 cursor-pointer ml-2  ">
                  {/* STEP 1 */}
                </li>
                <li id="2" className="w-1/3 py-4 px-2 cursor-pointer ml-2   ">
                  {/* STEP 2 */}
                </li>
                <li id="3" className="w-1/3 py-4 px-2 cursor-pointer ml-2   ">
                  {/* STEP 3 */}
                </li>
                
              </ul>
              
            </div>
          </section>
    </div>
  )
}

export default Stepper