import React from 'react'
// import '../css/book.css'

function Book(props) {
    return (props.trigger) ? (
        <div className='fixed top-0 left-0 bg-black/[0.4] flex justify-center items-center w-full h-screen'>
            <div className='relative bg-white w-full max-w-screen-md h-4/5 max-h-full p-[32px] overflow-y-auto rounded-3xl'>
                {/* <div className="close-container bg-blue-600 text-white rounded-lg w-1/12 p-3" onClick={() => props.setTrigger(false)}>
                    <div className="leftright"></div>
                    <div className="rightleft"></div>
                    <label className="close">Close</label>
                </div> */}
                <img
                src="https://live.staticflickr.com/65535/53116772283_7bb88e5308_w.jpg"
                alt="Close"
                className="w-10 h-10 cursor-pointer"
                onClick={() => props.setTrigger(false)}
                />



                {props.children}
            </div>
        </div>
    ) : "";
}

export default Book
