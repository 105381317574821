import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useNavigate } from 'react-router-dom';
import { auth, setProfile } from '../../pages/Firebase';

const Success = ({nextStep,prevStep,handleChange, values}) => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const Continue = (e) => {
    e.preventDefault();
    console.log("1");
    console.log(values);
    setProfile(values, user.uid);
    navigate("/dashboard");
  };
  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  return (
    <div className='bg-gray-100 rounded-lg'>
        <div>
          <h1 className=' p-8 m-8 text-4xl'>Are you sure you want to submit?</h1>
          <div className='flex flex-row rounded-sm space-x-2 p-2'>
          <button
            onClick={Previous}
            className="w-72  text-white bg-indigo-500 border-0 mx-4 my-2 py-2  focus:outline-none hover:bg-indigo-600 rounded text-lg"
          >
            Cancel
          </button>
          <button
            onClick={Continue}
            className="w-72   text-white bg-indigo-500 border-0 mx-4 my-2 py-2  focus:outline-none hover:bg-indigo-600 rounded text-lg "
          >
            YES
          </button>
          </div>
          

        </div>
    </div>
  )
}

export default Success