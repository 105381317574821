import React, { useEffect, useState } from 'react';
import allGenderSports from "../json/allSportsGender.json";
import "../components/profile.css";

export default function Registerdsport(props) {
    const registeredSports = props.registeredSports;
    const sportdetailselected = props.sportdetailselected;
    const [item, setItem] = useState(registeredSports[0]);
    useEffect(() => {
        for (let x in registeredSports) {
            if (props.sportdetailselected == registeredSports[x].sport) {
                setItem(registeredSports[x]);
            }
        }
    },[props.sportdetailselected]);

    const cellStyle = {
        textAlign: 'center',
        border: "1px solid black",
        borderCollapse: "collapse",
        padding: "10px", // Adjust padding as needed
        width: "25%", // Set uniform width for each column (25% for 4 columns)
    };

    console.log(registeredSports)
    return (
        <div className='flex justify-center'>
        <div className='user-sport-details-active'>
        <h1 style={{ fontWeight: 'bold' }}>Captaincy Details :</h1><br/>
            <table style={{
                textAlign: 'center', border: "1px solid black",
                border: "2px solid black", // Thickened border
                borderCollapse: "collapse",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", // Shadow
            }}>
                <tr>
                    <th style={cellStyle}>Name</th>
                    <th style={cellStyle}>Contact</th>
                    <th style={cellStyle}>Email</th>
                </tr>
                <tr>
                    <td style={cellStyle}>{item["firstNameCaptain"] || ""}  {item["lastNameCaptain"] || ""} (C)</td>
                    <td style={cellStyle}>{item["contactCaptain"] || ""}</td>
                    <td style={cellStyle}>{item["emailCaptain"] || ""}</td>
                </tr>
                <tr style={{
                    textAlign: 'center', border: "1px solid black",
                    borderCollapse: "collapse"
                }}>
                    <td style={{
                        textAlign: 'center', border: "1px solid black",
                        borderCollapse: "collapse"
                    }}>{item["firstNameViceCaptain"] || ""} {item["lastNameViceCaptain"] || ""} (VC)</td>
                    <td style={{
                        textAlign: 'center', border: "1px solid black",
                        borderCollapse: "collapse"
                    }}>{item["contactViceCaptain"] || ""}</td>
                    <td style={{
                        textAlign: 'center', border: "1px solid black",
                        borderCollapse: "collapse"
                    }}>{item["emailViceCaptain"] || ""}</td>
                </tr>

            </table>
            <br />
            <h1 style={{ fontWeight: 'bold' }}>Team Details :</h1>
            <br />
            <table style={{
                textAlign: 'center', border: "1px solid black",
                border: "2px solid black", // Thickened border             
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", // Shadow
                borderCollapse: "collapse"
            }}>
                <th style={cellStyle}>Player</th>
                {(sportdetailselected == "Taekwondo(Mix)")&&(<th style={cellStyle}>Gender</th>)}
                <th style={cellStyle}>Contact</th>

                {allGenderSports[item.sport].subEvents ? <th style={cellStyle}>Subevent</th> : null}
                {item["team"].map((player, index) => (
                    <tr key={index}>
                        <td style={cellStyle}>{player.player}</td>
                        {(sportdetailselected == "Taekwondo(Mix)")&&(<td style={cellStyle}>{player.gender}</td>)}
                        <td style={cellStyle}>{player.number}</td>
                        {allGenderSports[item.sport].subEvents ? <td style={cellStyle}>{player.subEvent}</td> : null}
                    </tr>
                ))}
            </table>
            </div>
        </div>
    )
}
