import React from 'react'
import './Page3.css'

const Page3 = ({nextStep,prevStep, handleChange, values}) => {
  const next= e => {
    if(values["collegeAddress"].length===0 || values["pincode"].length===0  || values["city"].length===0  || values["state"].length===0){
    
      alert("please fill all the required fields");
    }
    else{
      e.preventDefault();
      nextStep();
    }
  }
  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  return (
    <div>
      <div className="page3-container">
        <h2 className="page3-heading">
          Fill College Address Details
        </h2>
        <div className='page3-details'>
          <div className="page3-address">
            <label for="address" className="page3-address-label">
              College Address <span style={{color: 'red'}}>*</span> 
            </label>
            <input
              type="text"
              id="address"
              name="address"
              onChange={handleChange('collegeAddress')}
                  defaultValue={values.collegeAddress}
              className="page3-address-input page3-input"
            />
          </div>
          <div className="page3-pin">
            <label for="pincode" className="page3-pin-label">
              Pincode <span style={{color: 'red'}}>*</span>
            </label>
            <input
              type="number"
              id="pincode"
              name="pincode"
              onChange={handleChange('pincode')}
              defaultValue={values.pincode}
              className="page3-pin-input page3-input"
            />
          </div>
          <div className="page3-city">
            <label for="city" className="page3-city-label">
              City <span style={{color: 'red'}}>*</span>
            </label>
            <input
              type="text"
              id="city"
              name="city"
              onChange={handleChange('city')}
              defaultValue={values.city}
              className="page3-city-input page3-input"
            />
          </div>
          <div className="page3-state">
            <label for="state" className="page3-state-label">
              State <span style={{color: 'red'}}>*</span>
            </label>
            <input
              type="text"
              id="state"
              name="state"
              onChange={handleChange('state')}
              defaultValue={values.state}
              className="page3-state-input page3-input"
            />
          </div>
        </div> 
        
        
        
        
        <div className="page3-last">
          <button
            onClick={Previous}
            className="page3-prev page3-button"
          >
            Previous
          </button>
          <button
            onClick={next}
            className="page3-submit page3-button"
          >
            Submit
          </button>
        </div>
        
      </div>
    </div>
  )
}

export default Page3