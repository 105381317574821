import { useContext } from "react";
import { StepperContext } from "../../contexts/StepperContext";
import TextField from "../TextField";
function General() {
  const { userData, setUserData } = useContext(StepperContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  return (
    <div className="flex flex-col w-full">
      <div className="mb-10 mt-5">
        <h5 className="text-black font-bold text-xl mb-2 md:text-2xl after:content-['*'] after:ml-0.5 after:text-red-500 ">
          Provide Details of Captain and Vice-Captain
        </h5>
        
      </div>
      <div className="w-full flex-1">
        <h5 className="text-sm font-medium text-gray-600 mb-3">Captain's Details</h5>
        <div className="flex flex-col md:flex-row w-full items-center justify-center">
          <div className="flex-1 md:mr-2 mb-5 w-full">
            <TextField
              onChange={handleChange}
              value={userData["firstNameCaptain"] || ""}
              name="firstNameCaptain"
              label="First Name"
              placeholder="First Name"
              type="text"
              customClass="w-full"
              validations={["required", "min:4"]}
              required={true}
            />
          </div>
          <div className="flex-1 md:ml-2 mb-5 w-full">
            {" "}
            <TextField
              onChange={handleChange}
              value={userData["lastNameCaptain"] || ""}
              name="lastNameCaptain"
              label="Last Name"
              placeholder="last Name"
              customClass="w-full"
              type="text"
              validations={["required", "min:4"]}
              required={true}
            />
          </div>
          <div className="flex-1 md:ml-2 mb-5 w-full">
            {" "}
            <TextField
              onChange={handleChange}
              value={userData["emailCaptain"] || ""}
              name="emailCaptain"
              label="Email"
              placeholder="Email"
              customClass="w-full"
              type="email"
              validations={["required", "min:4"]}
              required={true}
            />
          </div>
          <div className="flex-1 md:ml-2 mb-5 w-full">
            {" "}
            <TextField
              onChange={handleChange}
              value={userData["contactCaptain"] || ""}
              name="contactCaptain"
              label="Contact Number"
              placeholder="Contact Number "
              customClass="w-full"
              type="text"
              validations={["required", "min:4"]}
              required={true}
            />
          </div>
        </div>
      </div>


      <div className="w-full flex-1">
        <h5 className="text-sm font-medium text-gray-600 mb-3">Vice-Captain's Details</h5>
        <div className="flex flex-col md:flex-row w-full items-center justify-center">
          <div className="flex-1 md:mr-2 mb-5 w-full">
            <TextField
              onChange={handleChange}
              value={userData["firstNameViceCaptain"] || ""}
              name="firstNameViceCaptain"
              label="First Name"
              placeholder="First Name"
              type="text"
              customClass="w-full"
              validations={["required", "min:4"]}
              required={true}
            />
          </div>
          <div className="flex-1 md:ml-2 mb-5 w-full">
            {" "}
            <TextField
              onChange={handleChange}
              value={userData["lastNameViceCaptain"] || ""}
              name="lastNameViceCaptain"
              label="Last Name"
              placeholder="last Name"
              customClass="w-full"
              type="text"
              validations={["required", "min:4"]}
              required={true}
            />
          </div>
          <div className="flex-1 md:ml-2 mb-5 w-full">
            {" "}
            
            <TextField
              onChange={handleChange}
              value={userData["emailViceCaptain"] || ""}
              name="emailViceCaptain"
              label="Email"
              placeholder="Email"
              customClass="w-full"
              type="email"
              validations={["required", "min:4"]}
              required={true}
            />
          </div>
          <div className="flex-1 md:ml-2 mb-5 w-full">
            {" "}
            <TextField
              onChange={handleChange}
              value={userData["contactViceCaptain"] || ""}
              name="contactViceCaptain"
              label="Contact Number"
              placeholder="Contact Number "
              customClass="w-full"
              type="text"
              validations={["required", "min:4"]}
              required={true}
            />
          </div>
        </div>
      </div>

      
      
    </div>
  );
}
export default General;
