import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import items from '../json/sportsRules.json';
import { useEffect } from "react";
function Sport() {
  const { event } = useParams();
  const [component, setComponent] = useState({});

  useEffect(() => {
    var temp = items.filter((item) => item.component === event);
    setComponent(temp[0]);
  }, []);

  return (
    <>
      {/* <a href='#'><img className='absolute h-[50px] top-[20px] right-[20px]' src='./pdf.png' /></a> */}
      <h1 className='text-4xl font-semibold pb-[20px] text-center'>Rules: {component.component}</h1>
      <p className='p-[10px]'>
        <ul className="list-disc leading-7">
          {
            component.points ? component.points.map((p) => {
              return <li>{p}</li>;
            }) : <></>
          }
        </ul>
      </p>
    </>
  )
}

export default Sport;