import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Competition from './pages/competitions';
import Sport from './components/Sport';
import Dashboard from "./pages/dashboard";
import RegistrationForm from "./pages/registrationForm";
import Loading from "./pages/loding";

function App() {
  return (
    <Router>
      <Routes>
      

        <Route path="/" element={<Competition  />} />
        <Route path="/dashboard/*" element={<Dashboard />} />

        <Route path="/sports/:event" element={<Sport />} />
        <Route path='/loading' element={<Loading />} />
        <Route path='/reg' element={<RegistrationForm />} />
       
      
      </Routes>

    
     
    </Router>
  );
}

export default App;
