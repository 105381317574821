// import { Rule } from "postcss";
import { useState } from "react";
import Stepper from "../components/Stepper";
import StepperControl from "../components/StepperControl";
import AddTeam from "../components/steps/AddTeam";
import ChooseSport from "../components/steps/ChooseSport";
import Confirm from "../components/steps/Confirm";
import General from "../components/steps/General";
import { StepperContext } from "../contexts/StepperContext";
import Validation from "./validation";
import allSportsGender from "../json/allSportsGender.json";

function Form(props) {
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState("");
  const [finalData, setFinalData] = useState([]);
  const [modalShow,setModalShow]=useState(false);
  const [radio, setRadio] =useState(false);
  const steps = [
    {
      id: 1,
      description: "Choose Sport",
      icon: `<svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none"><path d="M13.7266 2.98033C14.0623 3.3826 14.3317 3.82156 14.5346 4.28269C14.4967 4.32794 14.4565 4.37191 14.414 4.41444C13.755 5.07344 12.7505 5.17641 11.9835 4.72336L13.7266 2.98033ZM13.0194 2.27322L11.2765 4.0162C10.8236 3.24926 10.9266 2.24494 11.5855 1.58601C11.6281 1.54345 11.6721 1.50321 11.7174 1.46529C12.1784 1.66822 12.6173 1.93753 13.0194 2.27322ZM14.1207 9.48485C14.9259 8.24108 15.1779 6.74345 14.8769 5.34074C13.8286 6.18356 12.3455 6.21854 11.2612 5.44568L10.6714 6.03551L14.1207 9.48485ZM10.5541 4.73854C9.78141 3.65417 9.81649 2.17114 10.6594 1.12296C9.25655 0.821809 7.75878 1.07384 6.5149 1.87905L9.96426 5.3284L10.5541 4.73854ZM5.72316 2.50152C5.68528 2.53734 5.64777 2.57381 5.61067 2.61091C5.0894 3.13218 4.69464 3.73204 4.42639 4.37235C5.75968 4.76174 7.37381 5.43228 8.69898 6.59368L9.25715 6.03551L5.72316 2.50152ZM9.40887 7.29801L9.96426 6.74262L13.4982 10.2766C13.4624 10.3145 13.4259 10.352 13.3888 10.3891C12.8737 10.9043 12.2817 11.2959 11.6498 11.5639C11.2589 10.2283 10.5835 8.61763 9.40887 7.29801ZM11.0167 13.3743C11.0156 14.4011 10.0919 15.1854 9.02151 14.9575C7.54323 14.6428 5.29568 13.9325 3.70382 12.3407C2.11667 10.7535 1.39332 8.50531 1.06753 7.02108C1.03897 6.89093 1.02538 6.76289 1.02539 6.63834C1.02538 5.61412 1.94518 4.82643 3.01793 5.05062C4.51363 5.3632 6.787 6.07067 8.3803 7.66397C9.97682 9.26049 10.675 11.5271 10.9798 13.0139C11.0049 13.1365 11.0168 13.257 11.0167 13.3743ZM5.60331 8.8969C5.40805 8.70164 5.09146 8.70164 4.8962 8.8969C4.70094 9.09217 4.70094 9.40875 4.8962 9.60401L6.3962 11.104C6.59146 11.2993 6.90805 11.2993 7.10331 11.104C7.29857 10.9087 7.29857 10.5922 7.10331 10.3969L5.60331 8.8969Z" fill="currentColor"></path></svg>`,
    },
    {
      id: 2,
      description: "General Information",
      icon: `<svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none"><path d="M1 4.75C1 3.7835 1.7835 3 2.75 3H13.25C14.2165 3 15 3.7835 15 4.75V11.25C15 12.2165 14.2165 13 13.25 13H2.75C1.7835 13 1 12.2165 1 11.25V4.75ZM2.75 4C2.33579 4 2 4.33579 2 4.75V11.25C2 11.6642 2.33579 12 2.75 12H13.25C13.6642 12 14 11.6642 14 11.25V4.75C14 4.33579 13.6642 4 13.25 4H2.75ZM9.5 6C9.22386 6 9 6.22386 9 6.5C9 6.77614 9.22386 7 9.5 7H12.5C12.7761 7 13 6.77614 13 6.5C13 6.22386 12.7761 6 12.5 6H9.5ZM9.5 9C9.22386 9 9 9.22386 9 9.5C9 9.77614 9.22386 10 9.5 10H12.5C12.7761 10 13 9.77614 13 9.5C13 9.22386 12.7761 9 12.5 9H9.5ZM5.26138 7.71366C6.01074 7.71366 6.61821 7.10619 6.61821 6.35683C6.61821 5.60747 6.01074 5 5.26138 5C4.51203 5 3.90455 5.60747 3.90455 6.35683C3.90455 7.10619 4.51203 7.71366 5.26138 7.71366ZM3.85823 8.39207C3.38424 8.39207 3 8.77632 3 9.2503C3 10.0167 3.52158 10.6847 4.26507 10.8706L4.31845 10.8839C4.93754 11.0387 5.58522 11.0387 6.20432 10.8839L6.2577 10.8706C7.00119 10.6847 7.52277 10.0167 7.52277 9.2503C7.52277 8.77632 7.13852 8.39207 6.66454 8.39207H3.85823Z" fill="currentColor"></path></svg>`,
    },
    {
      id: 3,
      description: "Add Team Member",
      icon: `<svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 11a5 5 0 0 1 5 5v6H7v-6a5 5 0 0 1 5-5zm-6.712 3.006a6.983 6.983 0 0 0-.28 1.65L5 16v6H2v-4.5a3.5 3.5 0 0 1 3.119-3.48l.17-.014zm13.424 0A3.501 3.501 0 0 1 22 17.5V22h-3v-6c0-.693-.1-1.362-.288-1.994zM5.5 8a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM12 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8z"></path>
      </g>
  </svg>`,
    },
    {
      id: 4,
      description: "confirm",
      icon: `<svg className="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"></rect><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24L9 19L19 29L39 9L44 14L19 39L4 24Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>`,
    },
  ];
  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <ChooseSport registeredSports={props.registeredSports} radio={radio} setRadio={setRadio}/>;
      case 2:
        return <General  />;
      case 3:
        return <AddTeam />;
      case 4:
        return <Confirm onClose ={()=>setModalShow(false)} modalShow={modalShow}/>;
      default:
    }
  };
  const handleClick = (direction) => {
    let newStep = currentStep;
    if(direction === "next"){
      if(!Validation(newStep, userData,allSportsGender[userData.sport].subEvents)){
        return ;
      }
      
      newStep++;
    }else{
      newStep--;
    }
    
    
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };
  return (
    <div className="">
      <div style={{marginTop: '5rem'}}className="mt-5">
        <Stepper steps={steps} currentStep={currentStep} />
      </div>
      <StepperContext.Provider
        value={{
          userData,
          setUserData,
          finalData,
          setFinalData,
        }}
      >
        <div className="my-10 p-3 md:p-10 flex items-center justify-center">
          {displayStep(currentStep)}
        </div>
      </StepperContext.Provider>
      <div className="sticky bottom-0 bg-white z-10">
        <StepperControl
          steps={steps} openModal={()=>setModalShow(true)}
          currentStep={currentStep}
          handleClick={handleClick}
          radio={radio} setRadio={setRadio}
        />
      </div>
      {/* <Rules /> */}
    </div>
  );
}
export default Form;
