import React, { Component } from "react";
import Stepper from "../registerationForm/Stepper";
import Done from "./Done";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Success from "./Success";

export default class Signup extends Component {
  state = {
    step: 1,
    institute: "",
    contingent: "",
    full_name:"",
    email:"",
    yearOfStudy:"",
    gender:"",
    phoneNumber:"",
    whatsAppNumber:"",
    alternateNumber:"",
    collegeAddress:"",
    pincode:"",
    city:"",
    state:"",
    position:"",
    sports:{},
  };
  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };
  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };
  // handle field change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
    console.log(e)
  };

  render() {
    const { step } = this.state;
    const {
      institute,
      contingent,
      full_name,
      yearOfStudy,
      email,
      gender,
      phoneNumber,
      alternateNumber,
      whatsAppNumber,
      collegeAddress,
      pincode,
      city,
      state,
      position,
      sports,
    } = this.state;

    const values = { institute,contingent,full_name,gender,email,yearOfStudy, phoneNumber,alternateNumber,whatsAppNumber,collegeAddress, pincode, city, state, position, sports};
    switch (step) {
      case 1:

        return (
          <div>
          <Stepper
          step_ = {step}
          />
          <Page1

            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
          </div>
        );
      case 2:

        return (
          <div>
            <Stepper
            step_ = {step}
            />
            <Page2
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
          </div>
          
        );
      case 3:

        return (
          <div>
            <Stepper
            step_ = {step}
            />
            <Page3
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
          </div>
        );
      // case 4:
      //   return (
      //     <div>
      //       <Stepper
      //       step_ = {step}
      //       />
      //       <Page4
      //       prevStep={this.prevStep}
      //       nextStep={this.nextStep}
      //       handleChange={this.handleChange}
      //       values={values}
      //     />
      //     </div>
      //   );

      case 4:
        return <Success
        prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values} 
         />;

      // never forget the default case, otherwise VS code would be mad!
      default:
      return <Done/>
    }
  }
}
