import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { GiRuleBook } from "react-icons/gi";
import {
  MdOutlineSportsVolleyball,
  MdOutlineSportsTennis,
} from "react-icons/md";

import { BsTextIndentRight, BsTextIndentLeft } from "react-icons/bs";
import { logout } from "../pages/Firebase";

function cut_String(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

function Profile(props) {
  const registeredSports = props.registeredSports;
  const sportdetailselected = props.sportdetailselected;
  const userData = props.userData;
  const navigate = useNavigate();
  async function signOut() {
    // await logout(s);
    navigate("/");
  }
  const closeMenu = () => {
    setIsOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen); // console.log(props.image.toString())
  return (
    <>
      <aside
        className=" lg:w-[280px] w-full fixed z-10 overflow-hidden hover:overflow-y-auto"
        aria-label="Sidebar"
      >
        <div className=" lg:h-screen py-4 px-3 bg-gray-800">
          <div className=" hidden lg:space-y-5 lg:flex flex-col">
            <NavLink
              to=""
              className="flex items-center justify-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700"
            >
              <img
                src={props.image.toString()}
                alt="Alternate Text"
                className="w-40 h-40 rounded-full items-center"
                onError={(e) => {
                  e.target.src =
                    "https://cdn.vectorstock.com/i/preview-1x/62/59/default-avatar-photo-placeholder-profile-icon-vector-21666259.jpg";
                }}
              />
            </NavLink>

            <div className="my-2">
              <NavLink to="">
                <p className="flex items-center p-2 my-2 text-base font-normal rounded-lg text-white hover:bg-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 mr-2 transition duration-75 text-gray-300 group-hover:text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                    />
                  </svg>
                  <span className="ml-2 font-bold text-lg">
                    {userData.full_name}
                  </span>
                </p>
              </NavLink>

              <p className="flex items-center p-2 my-2 text-base font-normal  rounded-lg text-white hover:bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 mr-2 text-gray-300 transition duration-75 group-hover:text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                  />
                </svg>

                <span className="ml-2 font-bold text-lg">
                  {" "}
                  {cut_String(userData.institute, 16)}{" "}
                </span>
              </p>

              <div className="rounded-lg bg-gray-700 my-2 overflow-hidden z-30">
                <NavLink to="">
                  <p className="flex items-center p-2 text-base font-normal  rounded-lg text-white hover:bg-gray-900">
                    <svg
                      aria-hidden="true"
                      className="w-7 h-7 mr-2 text-gray-300 transition duration-75 group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <MdOutlineSportsVolleyball />
                    </svg>

                    <span className="ml-1 font-bold text-lg">
                      Registered Sports
                    </span>
                  </p>
                </NavLink>
                <NavLink to="registerdsports">
                  <div className="text-gray-400 my-2 text-lg max-h-36 overflow-hidden overflow-y-scroll ml-4">
                    {registeredSports.length > 0 &&
                      registeredSports.map((item) => (
                        <a
                          onClick={() => {
                            props.setSportdetailselected(item.sport);
                          }}
                          className="block py-1"
                        >
                          {item.sport}
                        </a>
                      ))}
                  </div>
                </NavLink>
              </div>
            </div>

            <NavLink
              to="form"
              className="flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700"
            >
              <svg
                aria-hidden="true"
                className="w-7 h-7 mr-2 text-gray-300 transition duration-75 group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <MdOutlineSportsTennis />
              </svg>
              <span className="ml-1 text-lg font-bold -my-1">Add Sports</span>
            </NavLink>

            <NavLink
              to="rules"
              className="flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700"
            >
              <svg
                aria-hidden="true"
                className="w-7 h-7 mr-2 text-gray-300 transition duration-75 group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <GiRuleBook />
              </svg>
              <span className="ml-1 text-lg font-bold -my-1">Rule Book</span>
            </NavLink>

            <li
              className="flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700 cursor-pointer"
              onClick={signOut}
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="ml-3 text-lg font-bold -my-1 cursor-pointer">
                Sign Out
              </span>
            </li>
          </div>

          <div className="flex justify-between items-center ml-2  lg:hidden">
            <div className="cursor-pointer rounded-xl bg-gray-700">
              <img
                src={props.image.toString()}
                alt="profile_img"
                className=" items-center w-14"
              />
            </div>

            <div
              className="guide-toggler  flex items-center cursor-pointer text-white focus:outline-none focus:ring-4 focus:ring-gray-200  rounded-lg text-sm p-2.5 bg-gray-600"
              type="button"
              id="guide-toggler"
              data-nav-open="false"
              onClick={toggle}
            >
              {isOpen ? (
                <BsTextIndentRight className="text-white h-6 w-6" />
              ) : (
                <BsTextIndentLeft className="text-white h-6 w-6" />
              )}
            </div>
          </div>

          <div
            className={`fixed top-[9%] right-0 left-0 z-50 w-screen h-screen md:hidden py-8 min-h-screen overflow-y-auto bg-gray-900 ${
              isOpen ? "block transition-transform" : "hidden"
            }`}
            id="guide-mobile-menu"
          >
            <NavLink to="" onClick={closeMenu}>
              <div className=" p-3 m-2 font-medium duration-500 relative text-base text-white rounded-lg hover:bg-gray-700 ">
                Profile Page
              </div>
            </NavLink>
            <NavLink to="rules" onClick={closeMenu}>
              <div className=" p-3 m-2 font-medium duration-500 relative text-base text-white rounded-lg hover:bg-gray-700 ">
                Rule Book
              </div>
            </NavLink>
            <div className=" p-3 m-2  flex flex-col font-medium duration-500 relative text-base text-white rounded-lg hover:bg-gray-700 ">
              <NavLink
                to=""
                className="hover:text-gray-300"
                onClick={closeMenu}
              >
                Registered Sports
              </NavLink>
              <NavLink to="registerdsports">
                <div className="text-gray-400 my-2 text-lg max-h-36 overflow-hidden overflow-y-scroll ml-4">
                  {registeredSports.length > 0 &&
                    registeredSports.map((item) => (
                      <a
                        onClick={() => {
                          closeMenu();
                          props.setSportdetailselected(item.sport);
                        }}
                        className="block py-1"
                      >
                        {item.sport}
                      </a>
                    ))}
                </div>
              </NavLink>
            </div>
            <div className=" p-3 m-2 font-medium duration-500 relative text-base text-white rounded-lg hover:bg-gray-700" onClick={ signOut }>
              <button>Sign-Out</button>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
}

export default Profile;
