import { useContext, useEffect, useState } from "react";
import { StepperContext } from "../../contexts/StepperContext";
import allSportsGender from "../../json/allSportsGender.json";
import "./choosesports.css";

function ChooseSport(props) {
  const registeredSports = props.registeredSports;
  const { userData, setUserData } = useContext(StepperContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    props.setRadio(true);
  };
  
  
  useEffect(()=>{
    registeredSports.forEach((sport)=>{
      const element = document.getElementById("li_"+ sport.sport);
      // element?.remove();
      element.classList.add('choose-sport-blur');
      const elem = document.getElementById("sport-"+ sport.sport);
      elem.disabled=true;

      // element.img.classList.add('choose-sport-img-blur');
      // element.classList.img.add('choose-sport-img-blur');
      // document.get
    })
  },[])

  return (
    <div className="flex flex-col w-full">
      <div className="mb-10 mt-5">
        <h5 className="text-black font-bold text-xl mb-2 md:text-2xl after:content-['*'] after:ml-0.5 after:text-red-500">
          Register for a Sport
        </h5>
        <p className="text-sm tracking-normal font-medium text-gray-600">
          <span style={{ fontWeight: "bold", color: "red" }}>
            Register for One Sport at a time.
          </span>
        </p>
      </div>
      <ul className="grid gap-6 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      {Object.keys(allSportsGender).map((key) => {
          return (
            <li className="mb-4 justify-self-center" id={"li_"+key}>
          <input
            type="radio"
            id={"sport-"+key}
            name="sport"
            value={key} 
            className="hidden peer"
            required=""
            onChange={handleChange}
            // checked={((userData["sport"] === key) ? true:false)}
          />
          <label
            htmlFor={"sport-" + key}
            className="flex flex-col justify-center items-center p-5 w-full text-gray-600 bg-white border-2 border-gray-200 cursor-pointer peer-checked:border-blue-600 peer-checked:shadow-md peer-checked:text-blue-600 hover:text-gray-600 label-container"
          >
            <div className="w-20 h-20">
              <img
                className=""
                src={allSportsGender[key]["url"].toString()}
                alt={key}
              />
            </div>
            <div className="w-full text-base capitalize text-center mt-1">
              {key}
            </div>
          </label>
        </li>
          )
        
          })
        }
      </ul>
      
     
    </div>
  );
}

export default ChooseSport;
