import React from "react";
import './Page2.css'

const Page2 = ({ prevStep, nextStep, handleChange, values }) => {
  const next= e => {
    if(values["full_name"].length===0 || values["email"].length===0 || values["yearOfStudy"].length===0 || values["gender"].length===0 || values["phoneNumber"].length===0 || values["whatsAppNumber"].length===0 ){
    
      alert("Please fill all the required fields");
    }
    else{
      e.preventDefault();
      nextStep();
    }
  }
  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  
  return (
    <div>
      <div className="page2-container">
        <h2 className="page2-heading">
          Fill Your Personal Details
        </h2>
        <div className="page2-details">
          <div className="page2-col1">
            <div className="page2-name">
              <label for="full_name" className="page2-name-label">
                Name <span style={{color: 'red'}}>*</span>
              </label>
              <input
                type="text"
                id="full_name"
                name="full_name"
                required
                onChange={handleChange('full_name')}
                    defaultValue={values.full_name}
                className="page2-name-input page2-input"
              />
            </div>
            <div className="page2-yos">
              <label for="yos" className="page2-yos-label">
                Year of Study <span style={{color: 'red'}}>*</span>
              </label>
              <input
                type="number"
                id="yos"
                name="yos"
                required
                onChange={handleChange('yearOfStudy')}
                    defaultValue={values.yearOfStudy}
                className="page2-yos-input page2-input"
              />
            </div>
            <div className="page2-gender">
              <label for="gender" className="page2-gender-label">
                Gender <span style={{color: 'red'}}>*</span>
              </label>
              <select
                name="gender"
                id="gender"
                required
                onChange={handleChange('gender')}
                defaultValue={values.gender}
                className="page2-gender-input page2-input"
              > <option value=""> Select</option>
                <option value="Female"> Female</option>
                <option value="Male"> Male</option>
                
                
                    
              </select>
            </div>
            <div className="page2-whatsapp">
              <label for="whatsapp" className="page2-whatsapp-label">
                Whatsapp No. <span style={{color: 'red'}}>*</span>
              </label>
              <input
                type="tel"
                id="whatsapp"
                name="whatsapp"
                req
                placeholder="9876543210"
                pattern="[0-9]{10}"
                onChange={handleChange('whatsAppNumber')}
                    defaultValue={values.whatsAppNumber}
                className="page2-whatsapp-input page2-input"
              />
                {/* <p className="invisible peer-invalid:visible text-red-700 font-light">Enter valid Phone Number</p> */}            <p className="invisible peer-invalid:visible text-red-700 font-light">Enter valid Phone Number</p>
            </div>
          </div>
          <div className="page2-col2">
            <div className="page2-email">
              <label for="email" className="page2-email-label">
                Email <span style={{color: 'red'}}>*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                onChange={handleChange('email')}
                    defaultValue={values.email}
                className="page2-email-input page2-input"
              />
            </div>
            
            <div className="page2-position">
              <label for="yos" className="page2-position-label">
                Your Position <span style={{color: 'red'}}>*</span>
              </label>
              <input
                type="text"
                id="position"
                name="position"
                required
                placeholder="Your Position in College"
                onChange={handleChange('position')}
                    defaultValue={values.position}
                className="page2-position-input page2-input"
              />
            </div>
            
            <div className="page2-phone">
              <label for="phone" className="page2-phone-label">
                Phone No. <span style={{color: 'red'}}>*</span>
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                required
                placeholder="9876543210"
                pattern="[0-9]{10}"
                onChange={handleChange('phoneNumber')}
                    defaultValue={values.phoneNumber}
                className="page2-phone-input page2-input"
              />
              {/* <p className="invisible peer-invalid:visible text-red-700 font-light">Enter valid Phone Number</p> */}
              <p className="invisible peer-invalid:visible text-red-700 font-light">Enter valid Phone Number</p>
            </div>
            {/* <div className="relative mb-4">
              <label for="phone" className="leading-7 text-sm text-gray-600 ">
                Phone Number <span style={{color: 'red'}}>*</span>
              </label>
              
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="9876543210"
                pattern="[0-9]{10}"
                required 
                onChange={handleChange('phoneNumber')}
                    defaultValue={values.phoneNumber}
                className="peer w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <p className="invisible peer-invalid:visible text-red-700 font-light">Enter valid Phone Number</p>
            </div>
            */}
            
            <div className="page2-alternate">
              <label for="alt-phone" className="page2-alternate-label">
                Alternate No.
              </label>
              <input
                type="tel"
                id="alt-phone"
                name="alt-phone"
                placeholder="9876543210"
                pattern="[0-9]{10}"
                onChange={handleChange('alternateNumber')}
                    defaultValue={values.alternateNumber}
                className="page2-alternate-input page2-input"
              />
              <p className="invisible peer-invalid:visible text-red-700 font-light">Enter valid Phone Number</p>
            </div>
          </div>
        </div>
        <div className="page2-bottom">
          <div className="page2-prev-next">
            <button
              onClick={Previous}
              className="page2-prev page2-last"
            >
              Previous
            </button>
            <button
              onClick={next}
              className="page2-next page2-last"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page2;
