function Hexagon(props) {
    return (
      <>
        <a  className="relative group flex flex-row justify-center mb-12 lg:mb-0 h-80 w-64 mx-5 transform transition duration-500 hover:scale-[1.1]">
          <div
            className={
              (props.borderDir === "left" ? "bg-blue-400 " : "bg-white") +
              " h-full flex-1 bg-opacity-50"
            }
            style={{ clipPath: "polygon(0 28.86%,100% 0, 100% 100%, 0 71%)" }}
          ></div>
          <div
            className={
              (props.borderDir === "right" ? "bg-blue-400" : "bg-white") +
              " h-full flex-1 bg-opacity-50"
            }
            style={{ clipPath: "polygon(0 0, 100% 28.86%, 100% 71%, 0 100%)" }}
          ></div>
          <div
            className="my-6 mx-5 shadow-sm p-10 flex flex-col justify-center bg-white absolute inset-0 transform  group-hover:scale-[0.95] transition duration-300"
            style={{
              clipPath:
                "polygon(50% 0, 100% 28.86%, 100% 71%, 50% 100%, 0 71%, 0 28.86%)",
            }}
          >
            <h5 className="uppercase text-xl font-bold text-center">
              {props.sportName}
            </h5>
            <img
              src={props.sportImage}
              className=""
              alt={props.sportName + " background"}
            ></img>
          </div>
        </a>
      </>
    );
  }
  export default Hexagon;
  