import { useEffect, useRef, useState } from "react";
import parser from 'html-react-parser';
const Stepper = ({ steps, currentStep }) => {
  const [newStep, setNewStep] = useState([]);
  const stepRef = useRef();
  const updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
  
    let count = 0;
    while (count < newSteps.length) {
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: false,
        };
      } else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
      } else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
      }
      count++;
    }
    return newSteps;
  };
  useEffect(() => {
    const stepsState = steps.map((step, index) =>
      Object.assign(
        {},
        {
          step: step,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );
    stepRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepRef.current);
    setNewStep(current);
  }, [steps, currentStep]);
  const displaySteps = newStep.map((step, index) => {
    return (
      <div
        className={
          (index !== newStep.length ? "w-full" : " ") + " flex items-center"
        }
        key={index}
      >
        <div
          className={`flex-auto border-t-2 w-1/2 transition duration-500 ease-in-out
         ${
           (step.completed || step.selected)
             ? "border-blue-600"
             : "border-gray-300"
         }`}
        ></div>
        <div className="relative flex flex-col items-center text-gray-300">
          <div
            className={`rounded-full transition duration-500 ease-in-out border-2   h-10 w-10 flex items-center justify-center py-3
          ${
            step.selected 
              ? " text-white font-bold border-blue-600  bg-blue-600"
              : "border-gray-300"
          }`}
          >
            {step.completed ? (
              parser(step.step.icon)
            ) : (
               parser(step.step.icon)
            )}
            
          </div>
          <div
            className={`absolute top-0 text-center mt-16 w-32 text-xs font-medium uppercase
           ${step.highlighted ? "text-blue-600 " : "text-gray-400 hidden md:block"}`}
          >
            {step.step.description}
          </div>
        </div>
        <div
          className={`flex-auto border-t-2 w-1/2 transition duration-500 ease-in-out
        ${step.completed ? "border-blue-600" : "border-gray-300"}`}
        ></div>
      </div>
    );
  });
  return (
    <>
      <div className="mx-4 py-4 md:p-6 flex justify-between items-center">
        {displaySteps}
      </div>
    </>
  );
};
export default Stepper;
