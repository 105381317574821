import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  signOut,
  deleteUser,
} from "firebase/auth";
import {
  getFirestore,
  addDoc,
  doc,
  collection,
  setDoc,
  query,
  where,
  getDoc,
  getDocs,
  collectionGroup,
  updateDoc,
} from "firebase/firestore";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";


const firebaseConfig = {
  apiKey: "AIzaSyBfKlmmlZYyeTKNaDTzqoYRfmXKe35_uMg",
  authDomain: "competitions-24.firebaseapp.com",
  projectId: "competitions-24",
  storageBucket: "competitions-24.appspot.com",
  messagingSenderId: "499383991962",
  appId: "1:499383991962:web:c681373b1c8baccdeaa923",
  measurementId: "G-TL3966ZFL0"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getFirestore(app);
const profileRef = collection(db, "Users24");

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  
  signInWithPopup(auth, provider)
    .then((result) => {
      const name = result.user.displayName;
      const email = result.user.email;
      const profilePic = result.user.photoURL;

      console.log("in");
      return result.user;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const signInWithFacebook = () => {
  const provider = new FacebookAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      const name = result.user.displayName;
      const email = result.user.email;
      const profilePic = result.user.photoURL;
    })
    .catch((error) => {
      console.log(error);
    });
};

// deleteUser((user) => {
//   if (user) {
//     console.log("user deleted");
//   } else {
//     console.log("user not exist");
//   }
// });

// getAuth()
//   .getUserByEmail(email)
//   .then((userRecord) => {
//     // See the UserRecord reference doc for the contents of userRecord.
//     console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
//   })
//   .catch((error) => {
  //     console.log("Error fetching user data:", error);
  //   });
  
  const logout = () => {
    // return signOut(auth);
    // navigate("/");
    // nav
  };
  
  const getProfile = async (id) => {
    const docRef = doc(db, "Users24", id);
    try {
    const docSnap = await getDoc(docRef);
    return docSnap;
  } catch (error) {
    console.log(error);
  }
};

const getSports = async (id) => {
  console.log(id);
  const collRef = doc(db, "Users24", id);
  console.log(collRef);
  try {
    console.log(collRef, "collRef");
    const tempdocSnaps = await getDoc(collRef);
    console.log(tempdocSnaps);
    const docSnaps = tempdocSnaps.data().sports;
    console.log(docSnaps);
    return docSnaps;
  } catch (error) {
    console.log(error);
  }
};

async function cbs(data, id, RBY) {
  console.log(data);
  console.log("test5");
  const sportName = data.sport;
  await setDoc(doc(db, "Users24", id), {"sports": {[sportName]: data}}, {
    merge: true,
  })
  .catch((error) => {
    console.log(error);
    console.log("test6");
  });
  console.log("test5");
  window.location.reload(true);
}
async function setProfile(data, id, RBY) {
  await setDoc(doc(db, "Users24", id), data, { merge: true });
}

// const uploadImage = async (image, id) => {
//   const data = new FormData();
//   data.append("file", image);
//   data.append("upload_preset", "geeky_images");
//   data.append("cloud_name", "udghosh");
//   await fetch("https://api.cloudinary.com/v1_1/udghosh/image/upload", {
//     method: "post",
//     body: data,
//   })
//   .then((resp) => resp.json())
//   .then((data) => {
//     setDoc(doc(db, "People", id), { url: data.url }, { merge: true });
//     console.log("---------" + data.url + "--------");
//     return data.url;
//   })
//   .catch((err) => console.log(err));
// };

function storeToSession(key, jsonValue) {
  sessionStorage.setItem(key, JSON.stringify(jsonValue));
}

function getFromSession(key) {
  return JSON.parse(sessionStorage.getItem(key));
}

export {
  storeToSession,
  getFromSession,
  logout,
  db,
  setProfile,
  // uploadImage,
  getProfile,
  cbs,
  getSports,
  deleteUser,
};
