import React, { useEffect, useState } from "react";
import Hexagon from "../components/hexagon";
import Logo from "./../components/logo.png";
import Popup from "reactjs-popup";
import {
  auth,
  getProfile,
  signInWithFacebook,
  signInWithGoogle,
} from "./Firebase";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

function Competition() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [openSignUp, setOpenSignUp] = useState(false);
  const closeSignUpModal = () => setOpenSignUp(false);
  async function showSignUpModal() {
    if (!user) {
      setOpenSignUp(true);
    } else {
      alert(
        "You are already signed up. Please register/login using login button."
      );
    }
    // alert("Registrations closed!")
  }

  const closeModal = () => setOpen(false);
  async function showModal() {
    if (user) {
      const docRef = await getProfile(user.uid);
      if (docRef.exists()) {
        navigate("/dashboard");
        return;
      }
      navigate("/reg");
    } else{
      alert("Please Authenticate first");
    };
  }

  const Facebook = () => {
    signInWithFacebook();
    closeSignUpModal();
  }

  const Google = () => {
    signInWithGoogle();
    closeSignUpModal();
  }

  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    // if (user) {
    //   async function callAsync() {
    //     const docRef = await getProfile(user.uid);
    //     if(docRef.exists()){

    //       navigate("/dashboard");
    //     }
    //     else if(user){
    //       navigate("/reg");
    //     }
    //     else{
    //       navigate("/");
    //     }
    //   }
    //   callAsync();

    // }
  }, [user, loading]);

  if (loading) {
    return <loading />;
  }

  return (
    <>
      <div className="bg-fixed h-auto bg-cover bg-no-repeat bg-center  bg-[url('https://live.staticflickr.com/65535/53014086318_39f1b986f0_k.jpg')]">
        <div className="h-screen overflow-hidden w-full flex  flex-col items-center justify-center bg-black bg-opacity-40">
          <div className="flex flex-row items-center justify-center mb-3">
            <img
              src={Logo}
              alt="Udghogh"
              className="max-h-12 inline-block mr-3"
            />
            <h3 className="text-white font-thin text-2xl  sm:text-4xl lg:text-6xl">
              UDGHOSH'24
            </h3>
          </div>
          <h1 className="text-white font-black text-3xl sm:text-5xl lg:text-8xl">
            COMPETITIONS
          </h1>
        </div>
      </div>
      <div className="w-full h-auto py-24 bg-fixed bg-cover bg-no-repeat bg-center  bg-[url('https://live.staticflickr.com/65535/52305606430_40ba7828eb_h.jpg')]">
        <div className="block md:hidden lg:block">
          <div className=" flex flex-col lg:flex-row items-center justify-center">
            <Hexagon
              sportName="Athletics"
              borderDir="left"
              sportImage="https://live.staticflickr.com/65535/52312199966_4be79e7e75_w.jpg"
            />
            <Hexagon
              sportName="Badminton"
              sportImage="//live.staticflickr.com/65535/52345655636_fa9218d45e_n.jpg"
            />
            <Hexagon
              sportName="basketball"
              borderDir="right"
              sportImage="https://live.staticflickr.com/65535/52312513238_7aeba39a52_w.jpg"
            />
          </div>
          <div className=" flex flex-col lg:flex-row items-center justify-center lg:-mt-16">
            <Hexagon
              sportName="Chess"
              borderDir="left"
              sportImage="//live.staticflickr.com/65535/52345960989_e9bca2e00d_z.jpg"
            />
            <Hexagon
              sportName="cricket"
              borderDir="right"
              sportImage="//live.staticflickr.com/65535/52345655506_afcbbc0b43_w.jpg"
            />

            {/* <Hexagon
              sportName="Weight lifting"
              borderDir="right"
              sportImage="https://live.staticflickr.com/65535/52312513158_7d4872c353_w.jpg"
            /> */}
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-center lg:-mt-16">
            <Hexagon
              sportName="Football"
              borderDir="left"
              sportImage="//live.staticflickr.com/65535/52346081405_d8c1db4e64_w.jpg"
            />
            <Hexagon
              sportName="Weight lifting"
              sportImage="https://live.staticflickr.com/65535/52312513158_7d4872c353_w.jpg"
            />
            <Hexagon
              sportName="Hockey"
              borderDir="right"
              sportImage="https://live.staticflickr.com/65535/52312512833_16e26e1003_w.jpg"
            />

            {/* <Hexagon
              sportName="Volleyball"
              borderDir="left"
              sportImage="https://live.staticflickr.com/65535/52312513168_c3bdfb59b3_w.jpg"
            />
            
            <Hexagon
              sportName="Table Tennis"
              borderDir="right"
              sportImage="https://live.staticflickr.com/65535/52312627659_5cac4cccb0.jpg"
            /> */}
          </div>
          <div className=" flex flex-col lg:flex-row items-center justify-center lg:-mt-16">
            <Hexagon
              sportName="Kabaddi"
              borderDir="left"
              sportImage="//live.staticflickr.com/65535/52345900268_8ed432f261.jpg"
            />
            <Hexagon
              sportName="KHO-KHO"
              borderDir="right"
              sportImage="//live.staticflickr.com/65535/52345900263_641f93d9a0_n.jpg"
            />

            {/* update  */}
            {/* <Hexagon
              sportName="Frisbee"
              borderDir="right"
              sportImage="//live.staticflickr.com/65535/52345900328_7242180fe3.jpg"
            /> */}
          </div>
          <div className=" flex flex-col lg:flex-row items-center justify-center lg:-mt-16">
            <Hexagon
              sportName="lawn Tennis"
              borderDir="left"
              sportImage="//live.staticflickr.com/65535/52312642335_f10eef07d9_w.jpg"
            />
            <Hexagon
              sportName="Mr. UDGHOSH"
              sportImage="https://png.pngitem.com/pimgs/s/184-1842023_bodybuilder-png-transparent-png.png"
            />

            <Hexagon
              sportName="Power Lifting"
              borderDir="right"
              sportImage="https://live.staticflickr.com/65535/52346081540_3773c68693.jpg"
            />
          </div>
          <div className=" flex flex-col lg:flex-row items-center justify-center lg:-mt-16">
            <Hexagon
              sportName="Squash"
              borderDir="left"
              sportImage="https://live.staticflickr.com/65535/52345655546_ff396412bc.jpg"
            />
            <Hexagon
              sportName="Table Tennis"
              borderDir="right"
              sportImage="https://live.staticflickr.com/65535/52312627659_5cac4cccb0.jpg"
            />

            {/* update  */}
            {/* <Hexagon
              sportName="Frisbee"
              borderDir="right"
              sportImage="//live.staticflickr.com/65535/52345900328_7242180fe3.jpg"
            /> */}
          </div>

          <div className=" flex flex-col lg:flex-row items-center justify-center lg:-mt-16">
            <Hexagon
              sportName="Taekwondo"
              borderDir="left"
              sportImage="//live.staticflickr.com/65535/52345900233_3ee17db6e8_z.jpg"
            />
            <Hexagon
              sportName="Ultimate Frisbee"
              sportImage="https://live.staticflickr.com/65535/52345900328_7242180fe3.jpg"
            />
            <Hexagon
              sportName="Volleyball"
              borderDir="right"
              sportImage="https://live.staticflickr.com/65535/52312513168_c3bdfb59b3_w.jpg"
            />
          </div>
          <div className=" flex flex-col lg:flex-row items-center justify-center lg:-mt-16">
            {/* <Hexagon
              sportName="wall-climbing"
              borderDir="left"
              sportImage="//live.staticflickr.com/65535/52345900178_e08eb3f62a.jpg"
            /> */}
            {/* <Hexagon
              sportName="Weight lifting"
              borderDir="right"
              sportImage="https://live.staticflickr.com/65535/52312513158_7d4872c353_w.jpg"
            /> */}
          </div>

          {/* <Hexagon
              sportName="wall-climbing"
              sportImage="//live.staticflickr.com/65535/52345900178_e08eb3f62a.jpg"
            /> */}
        </div>
      </div>
      <div className="fixed bottom-0 w-full space-x-12">
        <button
          className="px-12 my-2 mx-2 float-right py-5 bg-blue-800 text-white text-bg font-bold tracking-wide rounded-full focus:outline-none space-x-50"
          onClick={showModal}
        >
          Login
        </button>

        <button
          className="bottom-0 my-2 mx-3 float-right px-12 py-5 bg-blue-800 text-white text-bg font-bold tracking-wide rounded-full focus:outline-none"
          onClick={showSignUpModal}
        >
          Authenticate
        </button>
      </div>

      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <span>
          <div
            id="defaultModal"
            className="overflow-y-auto overflow-x lg:fixed md:fixed lg:w-full md:w-96 sm:min-w-full flex justify-center z-50 w-full md:inset-0 h-modal md:h-full sm:items-centre "
            onClick={closeModal}
          >
            <div
              className="relative p-4 w-full max-w-2xl h-full grid-2 object-center	"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="relative rounded-lg shadow bg-gray-900">
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-bold text-white ">Login</h3>
                  <button
                    onClick={closeModal}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-6 space-y-6">
                  <button
                    className="uppercase h-12 mt-3 text-white w-full rounded bg-blue-800 hover:bg-blue-900"
                    onClick={signInWithFacebook}
                  >
                    <i className="fa fa-facebook mr-2"></i>Facebook
                  </button>
                  <button
                    className="uppercase h-12 mt-3 text-white w-full rounded bg-red-800 hover:bg-red-900"
                    onClick={signInWithGoogle }
                  >
                    <i className="fa fa-google mr-2"></i>Google
                  </button>
                </div>
              </div>
            </div>
          </div>
        </span>
      </Popup>

      <Popup open={openSignUp} closeOnDocumentClick onClose={closeSignUpModal}>
        <span>
          <div
            id="defaultModal"
            className="overflow-y-auto overflow-x lg:fixed md:fixed lg:w-full md:w-96 sm:min-w-full flex justify-center z-50  md:inset-0 h-modal md:h-full "
            onClick={closeSignUpModal}
          >
            <div
              className="relative p-4 w-full max-w-2xl h-full grid-2 object-center 	"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="relative rounded-lg shadow bg-gray-900 items-center ">
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-bold text-white ">SignUp</h3>
                  <button
                    onClick={closeSignUpModal}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-6 space-y-6">
                  <button
                    className="uppercase h-12 mt-3 text-white w-full rounded bg-blue-800 hover:bg-blue-900"
                    onClick={Facebook}
                  >
                    <i className="fa fa-facebook mr-2"></i>Facebook
                  </button>
                  <button
                    className="uppercase h-12 mt-3 text-white w-full rounded bg-red-800 hover:bg-red-900"
                    onClick={Google} 
                  >
                    <i className="fa fa-google mr-2"></i>Google
                  </button>
                </div>
              </div>
            </div>
          </div>
        </span>
      </Popup>

      <div className=" h-24 w-full flex  flex-col items-center justify-center text-white bg-gray-800 cursor-text">
        <p>&copy;Udghosh2023</p>
        <p>All Rights Reserved</p>
      </div>
    </>
  );
}
export default Competition;
