import React, { useEffect, useState } from "react";
import Signup from "../components/registerationForm/Signup";
import Stepper from "../components/registerationForm/Stepper";
import p1 from "./bg1.png"

const RegistrationForm = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    console.log("Count is now: ", count);
  }, [count]);

  // const handleClick = event => {
  //   setCount(count + 1);
  // };
  return (
    <>
      <section className="grid grid-col-2 text-gray-600 body-font" >
      <div className="">
            <img className="w-1/3 max-h-screen bgimg "
              src={p1}
              alt=""
              style={{  width: "100%"}}
              
            />
      </div>
      <div className=" px-5 py-24  flex flex-col items-center w-1/2 absolute inset-y-0 right-0">
          
          
          <div>
            <br />
          <Signup/>
          </div>
          
          {/* <div className=" bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
            <h2 className="text-gray-900 text-lg font-medium title-font mb-5">
              Fill in Your Information
            </h2>
            <div className="relative mb-4">
              <label for="full-name" className="leading-7 text-sm text-gray-600">
                Institute Name
              </label>
              <input
                type="text"
                id="full-name"
                name="full-name"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label for="email" className="leading-7 text-sm text-gray-600">
                Contaigent Leader Name
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="flex space-x-4">
            <button className="w-1/2 text-white bg-indigo-500 border-0 my-2 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
              Previous
            </button>
            <button className=" w-1/2 text-white bg-indigo-500 border-0 my-2 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg ">
              Next
            </button>
            </div>
            <p className="text-xs text-gray-500 mt-3">
              Literally you probably haven't heard of them jean shorts.
            </p>
            
          </div> */}
        </div>
      </section>
    </>
  );
};

export default RegistrationForm;